<template>
  <div class="page-content">
    <KlubbaRichText class="texts">
      <p><b>Subscription completed</b></p>
      <p>We have sent you a welcome SMS with instructions on how to change, re-install your wallet pass, or cancel your subscription in the future.</p>
      <p>You may now close this window.</p>
    </KlubbaRichText>
  </div>
</template>

<script>
import KlubbaRichText from '../../components/default/KlubbaRichText.vue';

export default {
  name: 'SubscriptionAdded',
  components: { KlubbaRichText },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8
</style>
